<template>
  <div class="settings">
    <q-page-container>
      <q-page>
        <div class="settings-item">
          <div class="top">
            <div class="title">Mon compte</div>
          </div>
          <div class="bottom">
            <div class="line personal">
              <div class="left" v-if="getCurrentUser">
                {{ getCurrentUser.primaryDepartment.departmentName }} -
                {{ getCurrentUser.firstName }} {{ getCurrentUser.lastName }}
              </div>
              <div class="right">
                <q-btn
                  label="Changer le mot de passe"
                  class="btn-white"
                  @click="isShow = true"
                />

                <q-btn label="Se déconnecter" class="btn-blue" @click="logout">
                  <q-icon class="logout-icon" />
                </q-btn>
              </div>
            </div>
            <!-- <div class="line" @click="$router.push('/informations-management')">
              <div class="left">
                Gérer l'affichage des informations
              </div>
              <div class="right">
                <q-btn class="btn-arrow" type="button"></q-btn>
              </div>
            </div> -->
          </div>
        </div>

        <div
          class="settings-item"
          v-if="getCurrentUser && getCurrentUser.userRights.rightManageUsers"
        >
          <div class="top">
            <div class="title">Gestion des utilisateurs de Bylaw</div>
          </div>
          <div class="bottom">
            <div class="line" @click="$router.push('/user-management')">
              <div class="left">
                Gérer les utlisateurs
              </div>
              <div class="right">
                <q-btn class="btn-arrow" type="button"></q-btn>
              </div>
            </div>
          </div>
        </div>

        <div class="settings-item">
          <div class="top">
            <div class="title">Autres réglages</div>
          </div>
          <div class="bottom">
            <!-- <div class="line" @click="$router.push('/faq')">
              <div class="left">
                FAQs
              </div>
              <div class="right">
                <q-btn class="btn-arrow" type="button"></q-btn>
              </div>
            </div> -->
            <a href="https://www.bylaw.fr/politique-de-confidentialite/" target="_blank">
              <div class="line">
                <div class="left">
                  Politique de confidentialité
                </div>
                <div class="right">
                  <q-btn class="btn-arrow" type="button"></q-btn>
                </div>
              </div>
            </a>
            <a href="https://www.bylaw.fr/conditions-generales-dutilisation/" target="_blank">
              <div class="line">
                <div class="left">
                  Conditions générales d'utilisation
                </div>
                <div class="right">
                  <q-btn class="btn-arrow" type="button"></q-btn>
                </div>
              </div>
            </a>
          </div>
        </div>

        <div class="settings-item"
          v-if="getCompanyData">
          <div class="top">
            <div class="title">Pages analysées</div>
          </div>
          <div class="bottom">
            <div class="line">
              <div class="left">
                Période du {{ getCompanyData.periodStart }} au {{ getCompanyData.periodEnd }}
              </div>
              <div class="right">
                {{ getCompanyData.pageAnalysedCurrent }} / {{ getCompanyData.pageAnalysedTotal }}
              </div>
            </div>
          </div>
        </div>

        <div class="settings-app-version">
          <a>Version: {{ appVersion }}</a>
        </div>
      </q-page>
    </q-page-container>

    <ChangePasswordModal :is-show.sync="isShow" @closeModal="closeModal" />
  </div>
</template>

<script>
import ChangePasswordModal from '@/components/modals/ChangePassword'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'GeneralSettings',
  components: { ChangePasswordModal },
  data() {
    return {
      isShow: false,
      appVersion: '1.0.0'
    }
  },
  created() {
    this.fetchCompanyData()
  },
  computed: {
    ...mapGetters('users', ['getCurrentUser', 'getCompanyData'])
  },
  methods: {
    ...mapActions('users', ['clearCurrentUser', 'fetchCompanyData']),
    async logout() {
      this.clearCurrentUser()
      await this.$Amplify.Auth.signOut()
      this.$router.push('/login')
    },
    closeModal() {
      this.isShow = false
    },
    async setCrispData() {
      await this.sleep(1000);
      this.$crisp.push(['set', 'user:company', [process.env.VUE_APP_CLIENT_ID]])
      const current_user = this.getCurrentUser
      if (current_user != undefined) {
        this.$crisp.push(['set', 'user:email', [current_user.email]])
        this.$crisp.push(['set', 'user:nickname', [current_user.firstName + ' ' + current_user.lastName]])
      }
    },
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    }
  },
  mounted() {
    if (process.env.VUE_APP_APP_VERSION != undefined) {
      this.appVersion = process.env.VUE_APP_APP_VERSION
    }
    this.setCrispData()
  }
}
</script>
