<template>
  <q-dialog v-model="isShow" persistent>
    <q-card class="change-password-modal">
      <q-btn icon="close" @click="closeModal" flat round dense v-close-popup />
      <div class="logo"></div>
      <div class="form-container">
        <div>Enter your new password</div>
        <p class="mb-40">Type your new password in the field below.</p>
        <FormChangePassword :settings="true" @closeModal="closeModal" />
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import FormChangePassword from '@/components/login/FormChangePassword'

export default {
  name: 'ChangePasswordModal',
  components: { FormChangePassword },
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    }
  }
}
</script>
